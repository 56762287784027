import { defineMessages } from 'react-intl';

const vehicleMessages = defineMessages({
    referencePlaceholder: {
        id: 'vehicle.form.reference.placeholder',
        defaultMessage: 'Saisir un ID / une référence',
        description: 'placeholder',
    },
    ownership: {
        id: 'vehicle.ownership',
        defaultMessage: 'Propriété',
        description: 'form section title, list header',
    },
    plateNumber: {
        id: 'vehicle.plate_number',
        defaultMessage: 'Immatriculation',
        description: 'form section title, list header',
    },
    samsara: {
        id: 'vehicle.samsara',
        defaultMessage: 'Données provenant de SAMSARA',
        description: 'information tooltip',
    },
    mobileApp: {
        id: 'vehicle.mobile_app',
        defaultMessage: "Données provenant de l'application chauffeur",
        description: 'information tooltip',
    },
    managementIssues: {
        id: 'management.vehicles.issues.title',
        defaultMessage: 'Suivi des incidents',
        description: 'Tab',
    },
    mileage: {
        id: 'vehicle.mileage',
        defaultMessage: 'Kilométrage',
    },
    issueDetailTitle: {
        id: 'vehicle.issue.detail.title',
        defaultMessage: "Détail de l'incident",
    },
    issueDetailVehicleName: {
        id: 'vehicle.issue.detail.name',
        defaultMessage: 'Véhicule concerné',
    },
    issueDetailVehicleParcNumber: {
        id: 'vehicle.issue.detail.parcnumber',
        defaultMessage: 'N° de parc',
    },
    issueDetailVehicleIncident: {
        id: 'vehicle.issue.detail.incident',
        defaultMessage: 'Incident',
    },
    issueDetailVehicleCategory: {
        id: 'vehicle.issue.detail.category',
        defaultMessage: 'Catégorie',
    },
    issueDetailVehicleSubCategory: {
        id: 'vehicle.issue.detail.subcategory',
        defaultMessage: 'Sous-catégorie',
    },
    issueDetailVehicleState: {
        id: 'vehicle.issue.detail.state',
        defaultMessage: 'État',
    },
    issueDetailVehiclePicture: {
        id: 'vehicle.issue.detail.picture',
        defaultMessage: 'Photos',
    },
    issueDetailVehicleComment: {
        id: 'vehicle.issue.detail.comment',
        defaultMessage: 'Commentaire',
    },
    issueDetailVehiclePlaceHolder: {
        id: 'vehicle.issue.detail.placeholder',
        defaultMessage: 'Saisir un commentaire',
    },
    issueDetailVehicleSubmit: {
        id: 'vehicle.issue.detail.submit',
        defaultMessage: 'Valider la déclaration',
    },
    issueDetailVehiculeDeclare: {
        id: 'vehicle.issue.detail.declare',
        defaultMessage: 'Déclarer un incident',
    },
    issueDetailVehiculeDeclareSuccessMessage: {
        id: 'vehicle.issue.detail.declare.successmessage',
        defaultMessage: 'Incident déclaré avec succès',
    },
    change: {
        id: 'vehicule.change',
        defaultMessage: 'Changer de véhicule',
    },
    updateVehiculeSuccessMsg: {
        id: 'vehicule.update.success.msg',
        defaultMessage: 'Véhicule modifié avec succès',
    },
    updateVehiculeErrorTitle: {
        id: 'vehicule.update.error.title',
        defaultMessage: 'Impossible de changer le véhicule',
    },
    updateVehiculeErrorContent: {
        id: 'vehicule.update.error.content',
        defaultMessage: 'Le véhicule a déjà été récupéré.',
    },
});

export default vehicleMessages;
